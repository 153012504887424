<template>
  <div class="ccc">
    <TopHead></TopHead>
    <logoNav></logoNav>
    <div class="uc_con">
      <!--可用余额-->
      <div class="uc_mon d_f ali_b">
        <div class="ucm_title">可用余额</div>
        <div class="ucm_red">{{balance ? Number(balance/100).toFixed(2) : 0}}</div>
        <div> 总授信额度：</div>
        <div class="ucm_nor">{{creditAmount ? Number(creditAmount/100).toFixed(2) :'0'}}</div>
        <div> 可用授信余额：</div>
        <div class="flex_1 ucm_red">{{creditBalance ? Number(creditBalance/100).toFixed(2) :'0'}}</div>
      </div>
      <el-collapse v-model="activeNames">
        <!--基本信息-->
        <el-collapse-item name="1">
          <template #title>
            <div class="uc_title d_f ali_c flex_1">
              <div class="uc_green"></div>
              <div>基本信息</div>
              <div class="cut flex_1"></div>
            </div>
          </template>
          <div class="uc_card">
            <el-row>
              <el-col :span="3"><div class="t_r">分销类型：</div></el-col>
              <el-col :span="6"><div>{{basicInfo.typeName}}</div></el-col>
              <el-col :span="3"><div class="t_r">分销商名称：</div></el-col>
              <el-col :span="11"><div>{{basicInfo.name}}</div></el-col>
            </el-row>
            <el-row>
              <el-col :span="3"><div class="t_r">分销商简称：</div></el-col>
              <el-col :span="6"><div>{{basicInfo.abbreviation}}</div></el-col>
              <el-col :span="3"><div class="t_r">地址：</div></el-col>
              <el-col :span="11"><div>{{basicInfo.province+basicInfo.city+basicInfo.county+basicInfo.address}}</div></el-col>
            </el-row>
            <el-row>
              <el-col :span="3"><div class="t_r">联系人姓名：</div></el-col>
              <el-col :span="6"><div>{{basicInfo.contactName}}</div></el-col>
              <el-col :span="3"><div class="t_r">联系电话：</div></el-col>
              <el-col :span="11"><div>{{basicInfo.contactPhone}}</div></el-col>
            </el-row>
            <el-row>
              <el-col :span="3"><div class="t_r">联系人手机号码：</div></el-col>
              <el-col :span="6"><div>{{basicInfo.phoneNumber}}</div></el-col>
              <el-col :span="3"><div class="t_r">邮箱：</div></el-col>
              <el-col :span="11"><div>{{basicInfo.mailbox}}</div></el-col>
            </el-row>
            <el-row>
              <el-col :span="3"><div class="t_r">是否签约：</div></el-col>
              <el-col :span="6"><div>{{basicInfo.isSign ? '是' : '否'}}</div></el-col>
              <el-col :span="3"><div class="t_r">签约时间：</div></el-col>
              <el-col :span="11"><div>{{basicInfo.signDate}}</div></el-col>
            </el-row>
            <el-row>
              <el-col :span="3"><div class="t_r">简介：</div></el-col>
              <el-col :span="21"><div>{{basicInfo.synopsis}}</div></el-col>
            </el-row>
            <el-row>
              <el-col :span="3"><div class="t_r">备注：</div></el-col>
              <el-col :span="21"><div>{{basicInfo.remark}}</div></el-col>
            </el-row>
          </div>
        </el-collapse-item>
        <!--财务联系人信息-->
        <el-collapse-item name="2">
          <template #title>
            <div class="uc_title d_f ali_c flex_1">
              <div class="uc_green"></div>
              <div>财务联系人信息</div>
              <div class="cut flex_1"></div>
            </div>
          </template>
          <div class="uc_card">
            <el-row>
              <el-col :span="3"><div class="t_r">联系人姓名：</div></el-col>
              <el-col :span="6"><div>{{basicInfo.financeContact}}</div></el-col>
              <el-col :span="3"><div class="t_r">联系人电话：</div></el-col>
              <el-col :span="11"><div>{{basicInfo.financePhone}}</div></el-col>
            </el-row>
            <el-row>
              <!--<el-col :span="3"><div class="t_r">联系人手机：</div></el-col>-->
              <!--<el-col :span="6"><div>{{basicInfo.typeName}}</div></el-col>-->
              <el-col :span="3"><div class="t_r">电子邮箱：</div></el-col>
              <el-col :span="21"><div>{{basicInfo.financeMailbox}}</div></el-col>
            </el-row>
          </div>
        </el-collapse-item>
        <!--分销商银行卡信息-->
        <el-collapse-item name="3">
          <template #title>
            <div class="uc_title d_f ali_c flex_1">
              <div class="uc_green"></div>
              <div>分销商银行卡信息</div>
              <div class="cut flex_1"></div>
            </div>
          </template>
          <div class="uc_card">
            <el-row>
              <el-col :span="3"><div class="t_r">银行名称：</div></el-col>
              <el-col :span="6"><div>{{basicInfo.bankName}}</div></el-col>
              <el-col :span="3"><div class="t_r">开户行：</div></el-col>
              <el-col :span="11"><div>{{basicInfo.openingBank}}</div></el-col>
            </el-row>
            <el-row>
              <el-col :span="3"><div class="t_r">开户名：</div></el-col>
              <el-col :span="6"><div>{{basicInfo.cardHolder}}</div></el-col>
              <el-col :span="3"><div class="t_r">银行卡号：</div></el-col>
              <el-col :span="11"><div>{{basicInfo.bankNo}}</div></el-col>
            </el-row>
            <el-row>
              <el-col :span="3"><div class="t_r">预留手机号码：</div></el-col>
              <el-col :span="6"><div>{{basicInfo.phone}}</div></el-col>
            </el-row>
          </div>
        </el-collapse-item>
        <!--资质证书-->
        <el-collapse-item name="4">
          <template #title>
            <div class="uc_title d_f ali_c flex_1">
              <div class="uc_green"></div>
              <div>资质证书</div>
              <div class="cut flex_1"></div>
            </div>
          </template>
          <div class="uc_card">
            <el-row>
              <el-col :span="3"><div class="t_r">证照</div></el-col>
              <el-col :span="21">
                <div>
                  <div class="uc_img">
                    <div class="p_r" v-for="(item,index) in basicInfo.filePaths">
                      <!--<div class="p_a ali_c d_f j_c full">营业执照</div>-->
                      <!--<img :src="$global.host+'/uploadFile/downloadFile/'+item" alt="" class="full">-->
                      <img :src="item" alt="" class="full">
                    </div>
                </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-collapse-item>
      </el-collapse>

    </div>

  </div>
</template>

<script>
  export default {
    name: "userCenter",
    data() {
      return {
        activeNames: ['1','2','3','4'],
        basicInfo:{},
        typeArr:[],
        balance:0,
        creditAmount:0,
        creditBalance:0,
      }
    },
    created(){
      this.getEnumsByCode();
      this.getBusinessWallet();
    },
    methods: {
      //查询账户余额
      getBusinessWallet(){
        this.$api.getBusinessWallet().then(res => {
          this.balance = res.data.balance;
          this.creditAmount = res.data.creditAmount;
          this.creditBalance = res.data.creditBalance;
        })
      },
      //获取枚举
      getEnumsByCode(){
        this.$api.getEnumsByCode('business_type').then(res => {
          this.typeArr = res.data;
          this.getBusinessInfo();
        })
      },
      getBusinessInfo(){
        this.$api.getBusinessInfo().then(res => {
          this.basicInfo = res.data;
          this.typeArr.map(item => {
            if(item.value == res.data.typeId){
              this.basicInfo.typeName = item.name
            }
          })
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .uc_img{
    >.p_r{
      .p_a{
        z-index: 2;
        background: rgba(0, 0, 0, 0.5);
        font-size: 20px;
        font-weight: 400;
        color: #FFFFFF;
      }
      width: 191px;
      height: 191px;
      border-radius: 14px;
      overflow: hidden;
      margin-right: 100px;
      display: inline-block;
      margin-bottom: 20px;
      &:nth-child(4n){
        /*margin-right: 0;*/

      }
    }
    background: #FFFFFF;
    border: 1px solid #F0F1F3;
    border-radius: 6px;
    padding: 21px 30px;
    margin-left: 14px;
    display: inline-block;
  }
  .uc_mon{
    margin-bottom: 24px;
    line-height: 110px;
    .ucm_nor{
      font-size: 28px;
      font-weight: 400;
      margin:  0 120px 0 12px;
    }
    .ucm_red{
      margin-right: 320px;
      font-size: 42px;
      font-weight: bold;
      color: #FF3434;
    }
    .ucm_title{
      margin: 0 27px 0 25px;
      font-size: 28px;
      font-weight: bold;
    }
    font-size: 20px;
    font-weight: 400;
    color: #333333;
    height: 121px;
    background: #F0F1F3;
  }
  .uc_card{
    >.el-row{
      margin: 30px 0 60px 0;
    }
    font-size: 20px;
    font-weight: 400;
    color: #333333;
  }
  .uc_title{
    margin-bottom: 0;
  }
  :v-deep .el-collapse{
    border: none;
    .el-collapse-item__wrap{
      border: none;
    }
  }
  :v-deep .el-collapse-item__header{
    border-bottom: none;
    .el-collapse-item__arrow{
      &::before{
        content: '';
        display: block;
        height: 34px;
        width: 34px;
        background: url(../assets/images/uc_right.png) no-repeat;
        background-size: 100%;
      }
      &.is-active{
        transform: rotate(180deg);
      }
    }
  }
</style>
